const getJobsRoute = (parameters, locale, isCareerPage) => {
  if (parameters?.profilePage) {
    return `${isCareerPage ? 'careers-ebp' : 'company-ebp'}.${locale}`;
  }

  const jobsRoutePrefix = isCareerPage ? 'careers' : 'jobs';

  if (parameters?.locationSearch && !parameters?.keywords) {
    return `${jobsRoutePrefix}.onlyLocationSearch.${locale}`;
  }

  return `${jobsRoutePrefix}.${locale}`;
};

export default getJobsRoute;
