import React from 'react';
import NextError from 'next/error';

const throwError = (statusCode, code) => {
  if (process.browser) {
    return <NextError statusCode={statusCode} />;
  }

  const ssrError = new Error(`${statusCode}`);
  ssrError.statusCode = statusCode;
  ssrError.code = code;
  throw ssrError;
};

export default throwError;
