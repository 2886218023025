import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import MuiTabs, { TabsProps } from '@mui/material/Tabs';
import MuiTab, { TabProps as TabProperties } from '@mui/material/Tab';
import breakpoints from '../Theme/breakpoints';

const NavTabBar = React.forwardRef<HTMLButtonElement, TabsProps>(
  ({ ...properties }, reference) => (
    <MuiTabs
      ref={reference}
      component="nav"
      {...properties}
    />
  ),
);

const propertiesNotToForward = new Set(['originalCase', 'maxWidth']);

const tabPropertyTypes = {
  originalCase: PropTypes.bool,
  maxWidth: PropTypes.string,
};

type StyledTabProperties = InferProps<typeof tabPropertyTypes>;

const Tab = styled(React.forwardRef<HTMLDivElement, TabProperties>(
  (properties, reference) => (
    <MuiTab
      ref={reference}
      disableRipple
      {...properties}
    />
  ),
), {
  shouldForwardProp: (property) => !propertiesNotToForward.has(property),
})`
  ${({ originalCase = false } : StyledTabProperties) => originalCase && `
    && {
      text-transform: none;
    }
  `};
  max-width: ${({ maxWidth = 'none' } : StyledTabProperties) => maxWidth};
`;

Tab.propTypes = tabPropertyTypes;

const StyledNavTabBar = styled(NavTabBar)`
  && {
    display: none;

    @media (min-width: ${breakpoints.lg}px) {
      display: block;
    }
  }
`;

const navTabsPropertyTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    params: PropTypes.shape({}),
    route: PropTypes.string,
    value: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  value: PropTypes.string.isRequired,
};

type NavTabsProperties = InferProps<typeof navTabsPropertyTypes> & {
  NavTab: React.ElementType;
  onTabChange?: (value: string) => void;
};

const NavTabs = ({
  NavTab = Tab, value, tabs, onTabChange,
} : NavTabsProperties) => (
  <NavTabBar
    variant="fullWidth"
    indicatorColor="primary"
    textColor="primary"
    value={value}
  >
    {
      tabs.map(({
        label, route, params, value: tabValue,
      }) => (
        <NavTab
          key={label}
          test-id={label}
          route={route}
          params={params}
          value={tabValue}
          label={label}
          maxWidth={`calc(100% / ${tabs.length})`}
          onClick={() => onTabChange?.(tabValue)}
        />
      ))
}
  </NavTabBar>
);

NavTabs.propTypes = navTabsPropertyTypes;

export {
  StyledNavTabBar,
  NavTabBar,
  Tab,
  NavTabs,
};
