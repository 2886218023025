import React from 'react';

const schema = {
  about: {
    show: 'showAboutPage',
    html: 'aboutPageHtml',
  },
  'working-at': {
    show: 'showWorkingAtPage',
    html: 'workingAtPageHtml',
  },
  news: {
    show: 'showNewsPage',
    html: 'newsPageHtml',
  },
};

const getDescriptionText = (profile, parameters) => {
  const key = { ...schema[parameters?.profilePage] };
  const page = { ...profile?.page };

  return page[key.show] && (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: page[key.html] }}
    />
  );
};

export default getDescriptionText;
