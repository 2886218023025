/**
 * Filters id and slug.
 * @param {*} state useRouter
 * @returns Filtered state
 */

const filterQuery = (state) => {
  const { id, slug, ...query } = state.query;

  return {
    ...state,
    query,
  };
};

export default filterQuery;
