import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import camelCase from 'lodash/camelCase';
import stringify from 'fast-json-stable-stringify';
import { MUIButton } from '@fuww/library/src/Button';
import JobPageComponent from '@fuww/library/src/Jobs';
import {
  JobsDesktopOnlyContainer,
  JobsMobileOnlyContainer,
  DesktopOnlyContainer,
} from '@fuww/library/src/ImageContainer';
import Anchor from '@fuww/library/src/Anchor';
import Skeleton from '@fuww/library/src/SkeletonLoader/Skeleton';
import Head from '../../Head';
import HomeLink from '../../HomeLink';
import { useSiteContext } from '../../SiteContext';
import ImageWithUrls from '../../ImageWithUrls';
import LinkAndAnchor from '../../LinkAndAnchor';
import HeaderImage, {
  MobileOnlyImage,
  DesktopOnlyImage,
} from '../../Company/HeaderImage';
import filterQuery from '../../../lib/filterQuery';
import messages from '../../../lib/messages.mjs';
import throw404 from '../../../lib/throw404';
import getDescriptionText from '../../../lib/getDescriptionText';
import getJobsRoute from '../../../lib/getJobsRoute';
import debouncedReplaceRoute from '../../../lib/debouncedReplaceRoute';
import {
  getLocalizedCategoryParameters,
  categoryParametersToCategory,
  originalCategoryParameters,
} from '../../../lib/jobCategories';
import loading from '../../../lib/loading';
import { saveRouterState } from '../../../lib/savedRouterState';
import replaceRoute from '../../../lib/replaceRoute';
import safelyDecodeURIComponent from '../../../lib/safelyDecodeUriComponent';
import {
  jobSearchListLogoImageConfigurations,
} from '../../../lib/imageConfigurations';

const SearchFiltersWithCities = dynamic(() => import('./JobSearchFilters'), {
  ssr: true,
  loading,
});
const SearchFiltersWithoutCities = dynamic(() => import(
  '@fuww/library/src/Jobs/Search'
), {
  ssr: true,
  loading,
});
const JobList = dynamic(() => import('./JobSearchList'), {
  ssr: true,
  loading,
});
const JobSearchTabs = dynamic(() => import('./JobSearchTabs'), {
  ssr: true,
  loading,
});
const JobSearchNews = dynamic(() => import('./JobSearchNews'), {
  ssr: true,
  loading,
});

const PER_PAGE = 25;
const ALL_CATEGORIES_KEY = 'all-categories';
const resetSearchParameters = {
  keywords: null,
  locationSearch: null,
  categoryParam: null,
};

const JobSearchCompanyLogo = ({
  isCareerPage,
  profile,
  image,
}) => {
  const {
    page: {
      careerSiteReturnUrl,
    },
  } = profile;

  return isCareerPage && careerSiteReturnUrl ? (
    <Anchor
      href={careerSiteReturnUrl}
    >
      {image}
    </Anchor>
  ) : image;
};

JobSearchCompanyLogo.propTypes = {
  image: PropTypes.node.isRequired,
  isCareerPage: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    canUpdate: PropTypes.bool,
    company: PropTypes.shape({
      displayName: PropTypes.string,
      logoUrls: PropTypes.arrayOf(
        PropTypes.string,
      ),
    }),
    page: PropTypes.shape({
      careerSiteReturnUrl: PropTypes.string,
    }),
  }).isRequired,
};

const JobSearch = ({
  title,
  description,
  pageTitle,
  introduction,
  variables,
  categoryLabel,
  profile,
  city,
  position,
  headerImage,
  logo,
  hasTabs,
  showLoader,
  skipHighlightedJobsQuery,
  skipMainJobsListQuery,
  handleLocaleFilterChange,
  showJobsForAllLocales,
}) => {
  const intl = useIntl();
  const { locale, jobsComplete, isCareerPage } = useSiteContext();
  const { query } = filterQuery(useRouter());
  const {
    categoryParam,
    keywords: keywordsParameter,
    locationSearch: locationSearchParameter,
    companySlug,
    positionSlug,
  } = query;
  const { profilePage, ...rest } = query;
  const parametersWithResetSearch = {
    ...rest,
    ...resetSearchParameters,
  };
  const {
    page: {
      isEbpEnabled,
      showAboutPage,
      showNewsPage,
      showWorkingAtPage,
    } = {},
  } = { ...profile };

  if (!showLoader && profilePage && !isEbpEnabled) {
    throw404();
  }

  const categoryBreadcrumbs = categoryParam ? [
    <LinkAndAnchor
      route={`jobs.${locale}`}
      params={{ ...query, keywords: null, locationSearch: null }}
      useRouter={jobsComplete}
    >
      {categoryLabel}
    </LinkAndAnchor>,
  ] : [];

  const profileBreadcrumbs = profile ? [
    <LinkAndAnchor
      route={`jobs.${locale}`}
      params={parametersWithResetSearch}
      useRouter={jobsComplete}
    >
      {profile.company.displayName}
    </LinkAndAnchor>,
  ] : [];

  const cityBreadcrumbs = city ? [
    <LinkAndAnchor
      route={`jobs.${locale}`}
      params={parametersWithResetSearch}
      useRouter={jobsComplete}
    >
      {city.name}
    </LinkAndAnchor>,
  ] : [];

  const positionBreadcrumbs = position ? [
    <LinkAndAnchor
      route={`jobs.${locale}`}
      params={parametersWithResetSearch}
      useRouter={jobsComplete}
    >
      {position.name}
    </LinkAndAnchor>,
  ] : [];

  const breadcrumbs = isCareerPage ? [] : [
    <HomeLink />,
    <LinkAndAnchor
      route={`jobs.${locale}`}
      params={{
        ...parametersWithResetSearch,
        companySlug: null,
        citySlug: null,
        positionSlug: null,
      }}
      useRouter={jobsComplete}
    >
      {intl.formatMessage(messages.jobs)}
    </LinkAndAnchor>,
    ...profileBreadcrumbs,
    ...cityBreadcrumbs,
    ...positionBreadcrumbs,
    ...categoryBreadcrumbs,
  ];

  const showJobsList = !profilePage;
  const showNewsList = companySlug && profilePage === 'news';
  const showTabs = isEbpEnabled && [
    showAboutPage, showNewsPage, showWorkingAtPage,
  ].some(Boolean);
  const showCitySearchButtons = !(companySlug || positionSlug);

  const [keywords, setKeywords] = useState(
    safelyDecodeURIComponent(keywordsParameter || ''),
  );

  const [locationSearch, setLocationSearch] = useState(safelyDecodeURIComponent(
    locationSearchParameter || '',
  ));

  useEffect(() => {
    saveRouterState(
      isCareerPage ? 'careers' : 'jobs',
      { query },
    );
  }, [query, isCareerPage]);

  const SearchFilters = showCitySearchButtons
    ? SearchFiltersWithCities
    : SearchFiltersWithoutCities;

  const stringifiedQuery = stringify(query);
  const localizedCategories = stringify(originalCategoryParameters.map(
    (parameter) => ({
      label: intl.formatMessage(
        messages[`jobs.category.${parameter}.label`],
      ),
      value: intl.formatMessage(
        messages[`jobs.category.${parameter}.param`],
      ),
    }),
  ));
  const categoryOptions = useMemo(
    () => JSON.parse(localizedCategories).map(({ label, value }) => ({
      id: value,
      label,
      onClick: () => {
        const updatedQuery = {
          ...JSON.parse(stringifiedQuery),
          categoryParam: categoryParam === value ? null : value,
        };
        replaceRoute(
          getJobsRoute(updatedQuery, locale, isCareerPage),
          updatedQuery,
        );
      },
      selected: categoryParam === value,
    })),
    [
      categoryParam,
      isCareerPage,
      locale,
      stringifiedQuery,
      localizedCategories,
    ],
  );

  return (
    <>
      <Head
        title={title}
        description={description}
        payload={query}
      />
      <div />
      <JobPageComponent
        hideIntroTextOnMobile={Boolean(profilePage)}
        breadcrumbs={breadcrumbs}
        title={pageTitle || title}
        introduction={introduction}
        description={getDescriptionText(profile, query)}
        hasTabs={hasTabs}
        tabs={showTabs && (
          <JobSearchTabs
            profile={profile}
            params={query}
          />
        )}
        profileEditButton={profile?.canUpdate && (
          <LinkAndAnchor
            route={`company-ebp-edit.${locale}`}
            params={query}
          >
            <MUIButton>
              {intl.formatMessage(messages.editProfile)}
            </MUIButton>
          </LinkAndAnchor>
        )}
        search={showJobsList && (
          <SearchFilters
            handleLocaleFilterChange={handleLocaleFilterChange}
            showJobsForAllLocales={showJobsForAllLocales}
            checkboxLabel={
              intl.formatMessage(messages['jobs.showJobsForAllCountries'])
            }
            locale={locale}
            keywords={keywords}
            searchFieldTitle={intl.formatMessage(messages.searchTitle)}
            onSearchChange={({ target: { value } }) => {
              setKeywords(value);
              const updatedQuery = { ...query, keywords: value || null };
              debouncedReplaceRoute(
                getJobsRoute(updatedQuery, locale, isCareerPage),
                updatedQuery,
              );
            }}
            onSearchTrailingIconSelect={() => {
              setKeywords('');
              const updatedQuery = { ...query, keywords: null };
              replaceRoute(
                getJobsRoute(updatedQuery, locale, isCareerPage),
                updatedQuery,
              );
            }}
            locationSearch={locationSearch}
            locationSearchFieldTitle={intl.formatMessage(messages.location)}
            onLocationSearchChange={({ target: { value } }) => {
              setLocationSearch(value);
              const updatedQuery = { ...query, locationSearch: value || null };
              debouncedReplaceRoute(
                getJobsRoute(updatedQuery, locale, isCareerPage),
                updatedQuery,
              );
            }}
            onLocationSearchTrailingIconSelect={() => {
              setLocationSearch('');
              const updatedQuery = { ...query, locationSearch: null };
              replaceRoute(
                getJobsRoute(updatedQuery, locale, isCareerPage),
                updatedQuery,
              );
            }}
            categoryOptions={categoryOptions}
          />
        )}
        headerImage={headerImage}
        logo={logo}
      >
        {showJobsList && (
          <JobList
            variables={variables}
            skipHighlightedJobsQuery={skipHighlightedJobsQuery ?? showLoader}
            skipMainJobsListQuery={skipMainJobsListQuery ?? showLoader}
          />
        )}
        {showNewsList && (
          <JobSearchNews
            tagSlugs={profile?.company?.newsTags}
          />
        )}
      </JobPageComponent>
    </>
  );
};

JobSearch.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pageTitle: PropTypes.node,
  introduction: PropTypes.node,
  variables: PropTypes.shape({}),
  categoryLabel: PropTypes.string,
  profile: PropTypes.shape({
    canUpdate: PropTypes.bool,
    company: PropTypes.shape({
      displayName: PropTypes.string,
      newsTags: PropTypes.arrayOf(PropTypes.string),
    }),
    page: PropTypes.shape({
      isEbpEnabled: PropTypes.bool,
      showAboutPage: PropTypes.bool,
      showWorkingAtPage: PropTypes.bool,
      showNewsPage: PropTypes.bool,
    }),
  }),
  city: PropTypes.shape({ name: PropTypes.string }),
  position: PropTypes.shape({ name: PropTypes.string }),
  headerImage: PropTypes.node,
  logo: PropTypes.node,
  hasTabs: PropTypes.bool,
  showLoader: PropTypes.bool,
  handleLocaleFilterChange: PropTypes.func,
  showJobsForAllLocales: PropTypes.bool,
  skipHighlightedJobsQuery: PropTypes.bool,
  skipMainJobsListQuery: PropTypes.bool,
};

JobSearch.defaultProps = {
  title: null,
  description: null,
  pageTitle: null,
  introduction: null,
  variables: {},
  categoryLabel: null,
  profile: null,
  city: null,
  position: null,
  headerImage: null,
  logo: null,
  hasTabs: false,
  showLoader: false,
  skipHighlightedJobsQuery: undefined,
  skipMainJobsListQuery: undefined,
  handleLocaleFilterChange: null,
  showJobsForAllLocales: false,
};

const JobSearchPage = ({
  data, field, notFound, showLoader,
}) => {
  const intl = useIntl();
  const { jobsLocale: locale } = useSiteContext();
  const { query, route } = useRouter();
  const {
    categoryParam,
    keywords: keywordsParameter,
    locationSearch: locationSearchParameter,
    locales,
  } = query;
  const keywords = keywordsParameter && safelyDecodeURIComponent(
    keywordsParameter,
  );
  const locationSearch = locationSearchParameter && safelyDecodeURIComponent(
    locationSearchParameter,
  );
  const localizedCategoryParameters = getLocalizedCategoryParameters(intl);
  const originalCategoryParameter = localizedCategoryParameters[
    categoryParam
  ];
  const category = categoryParametersToCategory[originalCategoryParameter];

  const isCareerPage = route === '/careers';
  const showJobsForAllLocales = locales === 'all';

  const handleLocaleFilterChange = useCallback(({ target: { checked } }) => {
    const { locales: _locales, ...rest } = query;
    const updatedQuery = { ...query, keywords: null };

    replaceRoute(
      getJobsRoute(updatedQuery, locale, isCareerPage), {
        ...rest,
        ...(checked ? { locales: 'all' } : {}),
      },
    );
  }, [query, locale, isCareerPage]);

  if (!showLoader && categoryParam && !category) {
    return throw404();
  }

  const variables = {
    locales: showJobsForAllLocales ? null : [locale],
    category,
    keywords,
    locationSearch,
    first: PER_PAGE,
    logoConfigurations: jobSearchListLogoImageConfigurations,
  };

  const categoryKey = originalCategoryParameter || ALL_CATEGORIES_KEY;

  const categoryLabel = intl.formatMessage(
    messages[`jobs.category.${categoryKey}.label`],
  );

  if (notFound) {
    return (
      <JobSearch
        title={`${intl.formatMessage(messages.oops)
        } ${intl.formatMessage(messages['jobs.error.notFound.title'])
        }`}
        description={intl.formatMessage(
          messages[`jobs.category.${categoryKey}.description`],
        )}
        pageTitle={(
          <>
            {intl.formatMessage(messages.oops)}
            <br />
            {intl.formatMessage(messages['jobs.error.notFound.title'])}
          </>
        )}
        introduction={(
          <>
            {intl.formatMessage(messages['jobs.error.notFound.introduction'])}
            .
          </>
        )}
        categoryLabel={categoryLabel}
        variables={variables}
        showLoader={showLoader}
        field={field}
      />
    );
  }

  switch (field) {
    case 'profileBySlug': {
      const profile = data?.[field];

      if (!showLoader && !profile) return throw404();

      const {
        id, hasTabs, company, page,
      } = { ...profile };

      const {
        displayName,
        description,
        descriptions,
        logoUrls,
      } = { ...company };
      const { [camelCase(locale)]: localizedDescription } = { ...descriptions };

      return (
        <JobSearch
          title={intl.formatMessage(
            messages['jobs.company.title'],
            { company: displayName },
          )}
          description={intl.formatMessage(
            messages['jobs.company.description'],
            { company: displayName },
          )}
          introduction={(
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: localizedDescription || description,
              }}
            />
          )}
          categoryLabel={categoryLabel}
          variables={{
            ...variables,
            profileIdsOrKeywords: {
              profileIds: [id],
              keywords: page?.jobKeywords,
            },
          }}
          profile={profile}
          headerImage={showLoader ? (
            <Skeleton variant="rectangular" width="100%">
              <DesktopOnlyContainer />
            </Skeleton>
          ) : (
            <HeaderImage
              MobileOnlyContainer={JobsMobileOnlyContainer}
              DesktopOnlyContainer={JobsDesktopOnlyContainer}
              profile={profile}
              disableRoundedCorners
            />
          )}
          logo={showLoader ? (
            <Skeleton variant="rectangular" width="220px" height="220px" />) : (
            logoUrls[0] && (
            <JobSearchCompanyLogo
              isCareerPage={isCareerPage}
              profile={profile}
              image={(
                <ImageWithUrls
                  alt={displayName}
                  sizes="220px"
                  imageUrls={logoUrls}
                  objectFit="scale-down"
                  style={{ '--img-height': 'min-content' }}
                  srcSet={`${logoUrls[1]
                  } 220w, ${logoUrls[2]
                  } 440w, ${logoUrls[3]
                  } 660w`}
                  width="220px"
                />
              )}
            />
            ))}
          hasTabs={hasTabs}
          showLoader={showLoader}
          skipHighlightedJobsQuery={showLoader || !id}
          skipMainJobsListQuery={showLoader || !id}
          handleLocaleFilterChange={handleLocaleFilterChange}
          showJobsForAllLocales={showJobsForAllLocales}
          field={field}
        />
      );
    }
    case 'cityBySlug': {
      const city = data?.[field];

      if (!showLoader && !city) return throw404();

      const { id, name, imageUrls } = { ...city };

      return (
        <JobSearch
          title={intl.formatMessage(
            messages['jobs.city.title'],
            { city: name },
          )}
          description={intl.formatMessage(
            messages['jobs.city.description'],
            { city: name },
          )}
          introduction={intl.formatMessage(
            messages['jobs.city.introduction'],
            { city: name },
          )}
          categoryLabel={categoryLabel}
          variables={{ ...variables, cityId: id }}
          city={city}
          headerImage={showLoader ? (
            <Skeleton variant="rectangular" width="100%">
              <DesktopOnlyContainer />
            </Skeleton>
          ) : (imageUrls[0] && (
            <>
              <MobileOnlyImage
                title={name}
                imageUrls={[
                  imageUrls[0],
                  imageUrls[1],
                  imageUrls[2],
                ]}
                MobileOnlyContainer={JobsMobileOnlyContainer}
              />
              <DesktopOnlyImage
                title={name}
                imageUrls={[
                  imageUrls[0],
                  imageUrls[3],
                  imageUrls[4],
                  imageUrls[5],
                ]}
                DesktopOnlyContainer={JobsDesktopOnlyContainer}
              />
            </>
          ))}
          showLoader={showLoader}
          skipMainJobsListQuery={showLoader || !id}
          field={field}
        />
      );
    }
    case 'positionBySlug': {
      const position = data[field];

      if (!showLoader && !position) return throw404();

      const positionKey = camelCase(locale);
      const { id, name, names } = { ...position };

      return (
        <JobSearch
          title={intl.formatMessage(
            messages['jobs.position.title'],
            { position: names?.[positionKey] ?? name },
          )}
          description={intl.formatMessage(
            messages['jobs.position.description'],
            { position: name },
          )}
          introduction={intl.formatMessage(
            messages['jobs.position.introduction'],
            { position: name },
          )}
          categoryLabel={categoryLabel}
          variables={{ ...variables, positionId: id }}
          position={position}
          showLoader={showLoader}
          skipMainJobsListQuery={showLoader || !id}
          field={field}
        />
      );
    }
    default: {
      return (
        <JobSearch
          title={intl.formatMessage(
            messages[`jobs.category.${categoryKey}.title`],
          )}
          description={intl.formatMessage(
            messages[`jobs.category.${categoryKey}.description`],
          )}
          introduction={intl.formatMessage(
            messages[`jobs.category.${categoryKey}.introduction`],
          )}
          categoryLabel={categoryLabel}
          variables={variables}
          showLoader={showLoader}
          field={field}
        />
      );
    }
  }
};

JobSearchPage.propTypes = {
  data: PropTypes.shape({}),
  field: PropTypes.string,
  notFound: PropTypes.bool,
  showLoader: PropTypes.bool.isRequired,
};

JobSearchPage.defaultProps = {
  data: {},
  field: null,
  notFound: false,
};

export default JobSearchPage;
